import { useEffect } from "react";

export function useChatbox(shouldShow = true) {
  useEffect(() => {
    function configureOlark() {
      if (typeof window.olark === "undefined" || !shouldShow) return;

      window.olark("api.box.show");
    }

    setTimeout(() => {
      configureOlark();
    }, 0);
  }, [shouldShow]);
}
